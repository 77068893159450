import React from "react";
import Grid from '@mui/material/Grid';
import { Divider, Box, Container, Typography, Paper, Snackbar, SnackbarContent, Button, Collapse,
TextField, IconButton, Chip, Input } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";
import Img from 'react-cool-img'
import { firestore, removeFile, uploadFile } from '../firebase/firebase';
import { useLocation } from "react-router-dom";
import Sparkles from "../components/Sparkles";
import Sleeve from '../img/Sleeve.png'
import Marquee from "react-fast-marquee";
import { useCards } from "../providers/cardProvider";
import CardButtons from "../components/CardButtons/CardButtons";
import { useConfirm } from "material-ui-confirm";
import bleed from '../img/bleed.png'
import Meta from "../providers/meta";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CardTable from "../components/CardTable/CardTable";

import sprCHARACTER from '../img/sprite-character.png'
import sprEQUIPMENT from '../img/sprite-equipment.png'
import sprSACRIFICE from '../img/sprite-sacrifice.png'
import sprLOCATION  from '../img/sprite-location.png'
import sprVIRUS     from '../img/sprite-virus.png'
import sprGALAXY    from '../img/sprite-galaxy.png'
import sprGOD       from '../img/sprite-god.png'
import sprRITUAL    from '../img/sprite-ritual.png'
import sprHYBRID    from '../img/sprite-hybrid.png'
//import sprFREENIAN  from '../img/sprite-freenian.png'

import ExpansionBoxModel from "../components/ExpansionBoxModel/ExpansionBoxModel";

const Expansion = (props) => {
  const confirm = useConfirm();
  const { character, equipment, sacrifice, locations, virus, galaxy, god, ritual} = useCards();
  const matches = useMediaQuery('(max-width:600px)');
  const [edit, setEdit] = React.useState(false);
  const [oldData, setOldData] = React.useState([]);
  const [needsUpdate, setNeedsUpdate] = React.useState(false);
  const [expansionData, setExpansionData] = React.useState({});
  const [cards, setCards] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const [cardCounts, setCardCounts] = React.useState({});
  const location = useLocation()
  const [listOpen, setListOpen] = React.useState(false);
  const [artistsOpen, setArtistsOpen] = React.useState(false);
  const [viewingModel, setViewingModel] = React.useState(true);
  let path = new URLSearchParams(location.search).get("name");



  function getCardList(cardType) {
    switch(cardType) {
      case 'character': return character;
      case 'equipment': return equipment;
      case 'location': return locations;
      case 'virus': return virus;
      case 'ritual': return ritual;
      case 'sacrifice': return sacrifice;
      case 'god': return god;
      case 'galaxy': return galaxy;
      default: return [];
    }
  }

  function getCardIcon(cardType) {
    switch(cardType) {
      case 'character': return sprCHARACTER;
      case 'equipment': return sprEQUIPMENT;
      case 'location': return sprLOCATION;
      case 'virus': return sprVIRUS;
      case 'ritual': return sprRITUAL;
      case 'sacrifice': return sprSACRIFICE;
      case 'god': return sprGOD;
      case 'galaxy': return sprGALAXY;
      default: return sprCHARACTER;
    }
  }

  const getCardIndex = (id, name, type) => {
    let cardList = []
    cardList = getCardList(type).filter(card=> card.name.toLowerCase().replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === name)
    let index = cardList.findIndex((item) => {
      return item.id === id;
    })

    return index;
  }

  //create list of every unique artist in the cards array
  const getArtistList = () => {
    let artistList = []
    cards.forEach(card => {
      if(!artistList.includes(card.artist) && !card.artist.includes(',')){
        artistList.push(card.artist)
      }
    })
    return artistList;
  }


  const kfkArtists = [
    'Derek Miller-Young', 'Satia Solodky','Kyle Schnerch',
    'Brendan Miller-Young','Rebecca Johnston',
    'Jordan Landry','Kyle Hartshorne','Matt Jamieson',
    'Jordan Hunter','Dylan Froese','Kyle Smith','Jordan Hoeppner'
  ]


  const goToCharacter = (id, name, type) => {
    navigate(`/cards/${type}/${name}-${id}`, { state: { id: name},
  });
}

  const goToArtist = (name) => {
    navigate(`/artists/${name}`, { state: { id: name},
  });
}

  React.useEffect(() => {
      //if oldData and CharacterData are different, update firestore
      if(JSON.stringify(expansionData) !== JSON.stringify(oldData)){
        setNeedsUpdate(true);
    } else {
        setNeedsUpdate(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [expansionData])




  React.useEffect(() => {
      
    if(!expansionData !== []){
      if(!sessionStorage.getItem('expansions') ){
        
        setLoading(true)
        let exresponse = []
        //query firestore collection expanions for the state.expansion.id
        //set the expansion data to the expansion state
        //set the loading state to false
        firestore.collection('expansions').where('id', "==", path).get()
            .then(querySnapshot => {
              exresponse = querySnapshot.docs.map((doc) => {
                return {
                  ...doc.data(),
                    id: doc.id  
                }
              })
                setExpansionData(exresponse[0]);
                setOldData(exresponse[0]);
                setLoading(false);

            }).then(() => {
        
                  setLoading(true);
                  let equipmentResponse = []
                  let sacrificeResponse = []
                  let locationResponse = []
                  let virusResponse = []
                  let galaxyResponse = []
                  let godResponse = []
                  let ritualResponse = []
                  var cardCounts = {}
                  let response = [];
                  
                  if(!sessionStorage.getItem('characters')){
                    response = character
                    cardCounts = {...cardCounts, ...{'character': response.length}}
                  } else {
                    response = JSON.parse(sessionStorage.getItem('characters')).filter(card => card.origin === exresponse[0].name)
                      cardCounts = {...cardCounts, ...{'character': response.length}}
                  }

                  if(!sessionStorage.getItem('sacrifices')){
                      sacrificeResponse = sacrifice
                      cardCounts = {...cardCounts, ...{'sacrifice': sacrificeResponse.length}}
                  } else {
                      sacrificeResponse = JSON.parse(sessionStorage.getItem('sacrifices')).filter(card => card.origin === exresponse[0].name)
                      cardCounts = {...cardCounts, ...{'sacrifice': sacrificeResponse.length}}
                  }

                  if(!sessionStorage.getItem('locations')){
                    locationResponse = locations
                    cardCounts = {...cardCounts, ...{'location': locationResponse.length}}
                  } else {
                      locationResponse = JSON.parse(sessionStorage.getItem('locations')).filter(card => card.origin === exresponse[0].name)
                      cardCounts = {...cardCounts, ...{'location': locationResponse.length}}
                  }

                  if(!sessionStorage.getItem('viruses')){
                    virusResponse = virus
                    cardCounts = {...cardCounts, ...{'virus': virusResponse.length}}
                  } else {
                    virusResponse = JSON.parse(sessionStorage.getItem('viruses')).filter(card => card.origin === exresponse[0].name)
                      cardCounts = {...cardCounts, ...{'virus': virusResponse.length}}
                  }
                  if(!sessionStorage.getItem('rituals')){
                    ritualResponse = ritual
                    cardCounts = {...cardCounts, ...{'ritual': ritualResponse.length}}
                  } else {
                    ritualResponse = JSON.parse(sessionStorage.getItem('rituals')).filter(card => card.origin === exresponse[0].name)
                      cardCounts = {...cardCounts, ...{'ritual': ritualResponse.length}}
                  }

                  if(!sessionStorage.getItem('gods')){
                    godResponse = god
                    cardCounts = {...cardCounts, ...{'god': godResponse.length}}
                  } else {
                    godResponse = JSON.parse(sessionStorage.getItem('gods')).filter(card => card.origin === exresponse[0].name)
                      cardCounts = {...cardCounts, ...{'god': godResponse.length}}
                  }
                
                  if(!sessionStorage.getItem('galaxies')){
                    galaxyResponse = galaxy
                    cardCounts = {...cardCounts, ...{'galaxy': godResponse.length}}
                  } else {
                    galaxyResponse = JSON.parse(sessionStorage.getItem('galaxies')).filter(card => card.origin === exresponse[0].name)
                      cardCounts = {...cardCounts, ...{'galaxy': galaxyResponse.length}}
                  }
                    
                  if(!sessionStorage.getItem('equipments')){
                    equipmentResponse = equipment
                    cardCounts = {...cardCounts, ...{'equipment': equipmentResponse.length}}
                    setCardCounts({...cardCounts})
                    var cards2 = [...response, ...sacrificeResponse, ...equipmentResponse, ...locationResponse, ...virusResponse, ...galaxyResponse, ...godResponse, ...ritualResponse]
                    setCards(cards2);
                    setLoading(false);
                  } else {
                    equipmentResponse = JSON.parse(sessionStorage.getItem('equipments')).filter(card => card.origin === exresponse[0].name)
                    cardCounts = {...cardCounts, ...{'equipment': equipmentResponse.length}}
                    setCardCounts({...cardCounts})
                    var cards3 = [...response, ...sacrificeResponse, ...equipmentResponse, ...locationResponse, ...virusResponse, ...galaxyResponse, ...godResponse, ...ritualResponse]
                    setCards(cards3);
                    setLoading(false);
                  }
              
            }).catch (e => {
                setLoading(false);
            })
        } else if(!needsUpdate){
            setLoading(true);
            //get current url as string
            let url = window.location.href;
            //split the url by the / character
            let path = url.split('/')[4];


            let exresponse = JSON.parse(sessionStorage.getItem('expansions')).filter(expo => expo.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,'') === path)
            setExpansionData(exresponse.filter(card => card.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,'') === path)[0]);
            setOldData(exresponse.filter(card => card.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,'') === path)[0]);
            setLoading(false);
            setLoading(true);
                  let equipmentResponse = []
                  let sacrificeResponse = []
                  let locationResponse = []
                  let virusResponse = []
                  let galaxyResponse = []
                  let godResponse = []
                  let ritualResponse = []
                  var cardCounts = {}
                  let response = [];
                  
                  if(!sessionStorage.getItem('characters')){
                    response = character
                    cardCounts = {...cardCounts, ...{'character': response.length}}
                  } else {
                    response = JSON.parse(sessionStorage.getItem('characters')).filter(card => card.origin === exresponse[0].name)
                      cardCounts = {...cardCounts, ...{'character': response.length}}
                  }

                  if(!sessionStorage.getItem('sacrifices')){
                      sacrificeResponse = sacrifice
                      cardCounts = {...cardCounts, ...{'sacrifice': sacrificeResponse.length}}
                  } else {
                      sacrificeResponse = JSON.parse(sessionStorage.getItem('sacrifices')).filter(card => card.origin === exresponse[0].name)
                      cardCounts = {...cardCounts, ...{'sacrifice': sacrificeResponse.length}}
                  }

                  if(!sessionStorage.getItem('locations')){
                    locationResponse = locations
                    cardCounts = {...cardCounts, ...{'location': locationResponse.length}}
                  } else {
                      locationResponse = JSON.parse(sessionStorage.getItem('locations')).filter(card => card.origin === exresponse[0].name)
                      cardCounts = {...cardCounts, ...{'location': locationResponse.length}}
                  }

                  if(!sessionStorage.getItem('viruses')){
                    virusResponse = virus
                    cardCounts = {...cardCounts, ...{'virus': virusResponse.length}}
                  } else {
                    virusResponse = JSON.parse(sessionStorage.getItem('viruses')).filter(card => card.origin === exresponse[0].name)
                      cardCounts = {...cardCounts, ...{'virus': virusResponse.length}}
                  }
                  if(!sessionStorage.getItem('rituals')){
                    ritualResponse = ritual
                    cardCounts = {...cardCounts, ...{'ritual': ritualResponse.length}}
                  } else {
                    ritualResponse = JSON.parse(sessionStorage.getItem('rituals')).filter(card => card.origin === exresponse[0].name)
                      cardCounts = {...cardCounts, ...{'ritual': ritualResponse.length}}
                  }

                  if(!sessionStorage.getItem('gods')){
                    godResponse = god
                    cardCounts = {...cardCounts, ...{'god': godResponse.length}}
                  } else {
                    godResponse = JSON.parse(sessionStorage.getItem('gods')).filter(card => card.origin === exresponse[0].name)
                      cardCounts = {...cardCounts, ...{'god': godResponse.length}}
                  }
                
                  if(!sessionStorage.getItem('galaxies')){
                    galaxyResponse = galaxy
                    cardCounts = {...cardCounts, ...{'galaxy': godResponse.length}}
                  } else {
                    galaxyResponse = JSON.parse(sessionStorage.getItem('galaxies')).filter(card => card.origin === exresponse[0].name)
                      cardCounts = {...cardCounts, ...{'galaxy': galaxyResponse.length}}
                  }
                    
                  if(!sessionStorage.getItem('equipments')){
                    equipmentResponse = equipment
                    cardCounts = {...cardCounts, ...{'equipment': equipmentResponse.length}}
                    setCardCounts({...cardCounts})
                    var cards2 = [...response, ...sacrificeResponse, ...equipmentResponse, ...locationResponse, ...virusResponse, ...galaxyResponse, ...godResponse, ...ritualResponse]
                    setCards(cards2);
                    setLoading(false);
                  } else {
                    equipmentResponse = JSON.parse(sessionStorage.getItem('equipments')).filter(card => card.origin === exresponse[0].name)
                    cardCounts = {...cardCounts, ...{'equipment': equipmentResponse.length}}
                    setCardCounts({...cardCounts})
                    var cards3 = [...response, ...sacrificeResponse, ...equipmentResponse, ...locationResponse, ...virusResponse, ...galaxyResponse, ...godResponse, ...ritualResponse]
                    setCards(cards3);
                    setLoading(false);
                  }
          }} else {
            setLoading(false);
          }
        
  
  
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [loading])

  React.useEffect(() => {
  

} , [matches])

   //handle update characterData to document
   const handleUpdate = (e) => {
    setLoading(true);

      let response = []
      response = JSON.parse(sessionStorage.getItem('expansions'))
      
      response = response.filter(expo => expo.name !== expansionData.name)
      response.push(expansionData)
      firestore.collection('expansions').doc(expansionData.id).update(expansionData).then(() => {
          
          sessionStorage.setItem('expansions', JSON.stringify(response));
          setOldData(expansionData);
          setNeedsUpdate(false);
          setLoading(false);
      })
  
}

const handleImage = async (e) => {
  setLoading(true);
  //grab cardImage and delete it from storage
  removeImage(expansionData.boxImage);
  //change image name
  var name = expansionData.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,'')
  //eslint-disable-next-line
  name = name.replace(/[\(\)]/g, '')
  //strip # from name
  name = name.replace(/#/g, '')
  let path = 'boxes/' + name + '.png';

  //upload new image
  let image = await uploadFile(path, e.target.files[0]);
  expansionData.boxImage = image.url;
  //update firestore
  firestore.collection('expansions').doc(expansionData.id).update({
      boxImage: image.url
  }).then(() => {

      //update sessionStorage with new image
      let response = []
      response = JSON.parse(sessionStorage.getItem('expansions'))
      response = response.filter(expo => expo.id !== expansionData.id)
      response.push(expansionData)
      sessionStorage.setItem('expansions', JSON.stringify(response));
      setOldData(expansionData);
      setNeedsUpdate(false);
      setLoading(false);
  })
}


function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }


  const removeImage = async (url) => {

    let query = await removeFile(url);

    return query.fileDeleted;
  }

  const handleDelete = async (e) => {
    //alert yes or no?
    confirm({ description: "Deleting an expansion is permanent!" })
    .then(() => {
      setLoading(true);

      let response = []
      response = JSON.parse(sessionStorage.getItem('expansions'))
      response = response.filter(expo => expo.id !== expansionData.id)

      //grab cardImage and delete it from storage
      removeImage(expansionData.boxImage);

      firestore.collection('expansions').doc(expansionData.id).delete().then(() => {
          
          sessionStorage.setItem('expansions', JSON.stringify(response));
          navigate(-1);
          setLoading(false);
      })
    })
    .catch(() => {
      /* ... */
    });


  } 

  return (
    <div>
        
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              '#075d8e',
            flexGrow: 1,
            width: '100vw',
            marginTop:matches?"30%":"15%",
          }}
        >
          <CardButtons edit={edit} setEdit={setEdit} handleDelete={handleDelete}/>
          <Meta site_name={expansionData.name} title={expansionData.name+' - KFK Battalion Database'} 
      description={expansionData.description} 
      image={expansionData.boxImage}/>
        <Container maxWidth="lg" sx={{ p: 4 }}>
        <Grid container spacing={1}>
            <Paper style={{backgroundColor:'#101721', borderRadius:'10px', width:'100%', padding:'10px'}}>
                <Grid container spacing={1}>
                    <Grid item xs={3} sm={6} md={6} lg={6} xl={6}>
                        {edit ? <React.Fragment>
                          <Input
                            id="uploadImage"
                            type='file'
                            accept="image/*"
                            name="boxImage"
                            disabled={props.saving && loading}
                            onChange={(e) => { handleImage(e)}}
                            required/>

                            <Img
                            src={expansionData.boxImage}
                            alt={expansionData.name}
                            style={props.matches ? {width:'150px', margin:'0px'}:{width:'500px', margin:'0px'}}
                          />
                          </React.Fragment>
                        :<ExpansionBoxModel expansionData={expansionData} matches={matches} viewingModel={viewingModel}/>}
                        <Button
                          onClick={()=>{setViewingModel(!viewingModel)}}
                        >View {viewingModel ? 'Image' : '3D Model'}</Button>
                    </Grid>
                    <Grid item xs={9} sm={6}  md={6} lg={6} xl={6}>
                        {edit ?
                        <TextField sx={{ color:'white', width:'100%'}}
                          InputLabelProps={{ style: { color: 'white' } }}
                          inputProps={{ style: { color: 'white', fontSize: '1.5rem' } }}
                          label="Expansion Name"
                          variant="outlined"
                          value={expansionData.name}
                          onChange={(e) => setExpansionData({...expansionData, name:e.target.value})}
                        />
                        :
                        <Typography variant={matches ? "body1" :"h4"} component="h1" style={{color:'white'}}>{expansionData.name}</Typography>}
                        {edit ?
                        <TextField sx={{ color:'white'}}
                          InputLabelProps={{ style: { color: 'white' } }}
                          inputProps={{ style: { color: 'white' } }}
                          label="Date"
                          variant="outlined"
                          value={expansionData.year}
                          onChange={(e) => setExpansionData({...expansionData, year:e.target.value})}
                        /> :
                        <Typography variant="subtitle1" component="h1" style={{color:'white'}}>Printed {expansionData.year}</Typography>}
                        <Divider style={{backgroundColor:'white', marginBottom:'2px'}}/>
                        <Grid container spacing={2} style={{justifyContent:'center'}}>
                          <Grid item xs={1}>
                            <Img src={sprCHARACTER} style={{border:'1px dashed grey', borderRadius:'3px'}}/>
                          </Grid>
                          <Grid item xs={1}>
                            <Img src={sprEQUIPMENT} style={{border:'1px dashed grey', borderRadius:'3px'}}/>
                          </Grid>
                          <Grid item xs={1}>
                            <Img src={sprSACRIFICE} style={{border:'1px dashed grey', borderRadius:'3px'}}/>
                          </Grid>
                          <Grid item xs={1}>
                            <Img src={sprLOCATION} style={{border:'1px dashed grey', borderRadius:'3px'}}/>
                          </Grid>
                          <Grid item xs={1}>
                            <Img src={sprVIRUS} style={{border:'1px dashed grey', borderRadius:'3px'}}/>
                          </Grid>
                          <Grid item xs={1}>
                            <Img src={sprGALAXY} style={{border:'1px dashed grey', borderRadius:'3px'}}/>
                          </Grid>
                          <Grid item xs={1}>
                            <Img src={sprGOD} style={{border:'1px dashed grey', borderRadius:'3px'}}/>
                          </Grid>
                          <Grid item xs={1}>
                            <Img src={sprRITUAL} style={{border:'1px dashed grey', borderRadius:'3px'}}/>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{justifyContent:'center'}}>
                          <Grid item xs={1}>
                            <Typography variant="body1" component="h1" style={matches ?{color:'white',fontSize:'6px'} :{color:'white',}}> {cardCounts.character || 0}  </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography variant="body1" component="h1" style={matches ?{color:'white',fontSize:'6px'} :{color:'white',}}> {cardCounts.equipment || 0} </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography variant="body1" component="h1" style={matches ?{color:'white',fontSize:'6px'} :{color:'white',}}> {cardCounts.sacrifice || 0} </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography variant="body1" component="h1" style={matches ?{color:'white',fontSize:'6px'} :{color:'white',}}> {cardCounts.location || 0} </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography variant="body1" component="h1" style={matches ?{color:'white',fontSize:'6px'} :{color:'white',}}> {cardCounts.virus || 0} </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography variant="body1" component="h1" style={matches ?{color:'white',fontSize:'6px'} :{color:'white',}}> {cardCounts.galaxy || 0} </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography variant="body1" component="h1" style={matches ?{color:'white',fontSize:'6px'} :{color:'white',}}> {cardCounts.god || 0} </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography variant="body1" component="h1" style={matches ?{color:'white',fontSize:'6px'} :{color:'white',}}> {cardCounts.ritual || 0} </Typography>
                          </Grid>
        
                        </Grid>
                        <Divider style={{backgroundColor:'white', marginBottom:'2px'}}/>
                        <Typography variant="body1" component="h1" style={matches ?{color:'white', fontSize:'8px', marginBottom:'40px'}:{color:'white', marginBottom:'40px'}}><b>Total Cards: {(cardCounts.character || 0) +( cardCounts.equipment || 0) + (cardCounts.sacrifice  || 0)+ (cardCounts.location || 0) +( cardCounts.virus  || 0)+ (cardCounts.galaxy || 0 )+ (cardCounts.god || 0) + (cardCounts.ritual || 0)}</b></Typography>
                        {edit ?
                        <TextField sx={{ color:'white', width:'500px'}}
                          InputLabelProps={{ style: { color: 'white' } }}
                          inputProps={{ style: { color: 'white' } }}
                          label="Description"
                          multiline
                          rows={4}
                          variant="outlined"
                          value={expansionData.desc}
                          onChange={(e) => setExpansionData({...expansionData, desc:e.target.value})}
                        /> :
                        <Typography variant="body1" component="h5" style={matches?{color:'white',fontSize:'10px', marginBottom:'20px'}:{color:'white', marginBottom:'20px'}}>{expansionData.desc}</Typography>}
                        <Grid container style={{justifyContent:'left',paddingTop:'30px'}}>
                          <Grid item xs={3}>
                            <Typography variant="body1" component="h5" style={matches?{color:'white',fontSize:'10px'}:{color:'white'}}>Contributing Artists</Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton onClick={()=>{setArtistsOpen(!artistsOpen)}}
                            style={{bottom:'12px'}}>{artistsOpen ? <ExpandLessIcon style={{color:'white'}}/> : <ExpandMoreIcon style={{color:'white'}}/>}</IconButton>
                          </Grid>
                        </Grid>

                        <Divider style={{backgroundColor:'white', marginBottom:'2px'}}/>
                        <Collapse in={artistsOpen} timeout='auto' unmountOnExit>
                        <Grid container style={{paddingTop:'20px'}}>
                          <Grid container item xs={12} style={{paddingBottom:'6px', justifyContent:'center'}}>
                            <Grid item xs={3}>
                              <Typography variant="body1" style={{color:'white',fontSize:'10px'}}><Chip size="small" style={{fontSize:"10px", color:'black', backgroundColor:'#36a1df'}} />&nbsp;&nbsp;KFK Artist</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography variant="body1" style={{color:'white',fontSize:'10px'}}><Chip size="small" style={{fontSize:"10px", color:'black', backgroundColor:'white'}} />&nbsp;&nbsp;Guest Artist</Typography>
                            </Grid>
                          </Grid>
                        {getArtistList().sort(
                          //alphabetical sort
                          function(a, b) {
                            var textA = a.toUpperCase();
                            var textB = b.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                          }
                        ).map((artist, index) => {
                          return(
                            <Grid item xs={3} key={index}>
                            <Typography variant="body1" component="h5" onClick={()=>{goToArtist(artist)}}
                            style={matches?{color:kfkArtists.includes(artist)?'#36a1df':'white',cursor:'pointer',fontSize:'10px'}:{color:kfkArtists.includes(artist)?'#36a1df':'white',cursor:'pointer',fontSize:'10px',}} key={index}>{artist}</Typography>
                            </Grid>
                          )
                        })}
                        </Grid>
                        </Collapse>

                        <Grid container style={{justifyContent:'left',paddingTop:'30px'}}>
                          <Grid item xs={1}>
                            <Typography variant="body1" component="h5" style={matches?{color:'white',fontSize:'10px'}:{color:'white'}}>Catalog</Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton onClick={()=>{setListOpen(!listOpen)}}
                            style={{bottom:'12px'}}>{listOpen ? <ExpandLessIcon style={{color:'white'}}/> : <ExpandMoreIcon style={{color:'white'}}/>}</IconButton>
                          </Grid>
                        </Grid>

                        <Divider style={{backgroundColor:'white', marginBottom:'2px'}}/>
                        <Collapse in={listOpen} timeout='auto' unmountOnExit>
                          <Grid container spacing={1} style={{marginBottom:'20px',paddingTop:'20px'}}>
                            <Grid container item xs={12} style={{paddingBottom:'6px', justifyContent:'center'}}>
                              <Grid item xs={3}>
                                <Typography variant="body1" style={{color:'white',fontSize:'10px'}}><Chip size="small" style={{fontSize:"10px", color:'black', backgroundColor:'white'}} />&nbsp;&nbsp;Normal</Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography variant="body1" style={{color:'white',fontSize:'10px'}}><Chip size="small" style={{fontSize:"10px", color:'black', backgroundColor:'yellow'}} />&nbsp;&nbsp;Foil</Typography>
                              </Grid>
                            </Grid>
                              {!loading && cards.sort(
                                //alphabetical sort
                                function(a, b) {
                                  var textA = a.name.toUpperCase();
                                  var textB = b.name.toUpperCase();
                                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                }
                              ).map((card, index) => {
                                return(
                                  
                                  <Grid item xs={4} key={index}>
                                    <Typography align="left" variant="body1" style={{color:card.foil?'yellow':'white', fontSize:'10px'}}><Img src={card.hybrid ?sprHYBRID : getCardIcon(card.type)} style={{width:'10px',padding:'0px',margin:'0px',bottom:'-10px'}}/>&nbsp;&nbsp;#{index+1}: {card.name}</Typography>
                                  </Grid>
                 
                                )
                              })}
                          </Grid>
                        </Collapse>
                    </Grid>
                    <Grid item xs={12} sm={12}  md={12} lg={12} xl={12}>
                        
                    <Marquee gradientColor={[0,0,0]} speed={matches?5:20} gradientWidth={200} pauseOnHover={true} style={{borderRadius:'10px', marginBottom:'30px', overflow:'hidden'}}>
            {!loading &&
            shuffle(cards).map((card, index) => {
                return (
                            card.foil || card.cloudyfoil ? 
                            <Sparkles key={index}>
                                <Img onClick={() => goToCharacter(getCardIndex(card.id, card.name.toLowerCase().replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, ''), card.type), card.name.toLowerCase().replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, ''), card.type)} placeholder={Sleeve} src={card.cardImage} alt={card.name} 
                                style={matches?{borderRadius:'5px',width:'100px', margin:'0px', zIndex:'1', marginRight:'2px', bottom:'100px',cursor:'pointer',
                                maskImage: `url(${bleed})`,
                                maskSize: '100px auto',
                                maskRepeat: 'no-repeat',
                                maskPosition: 'center',
                                WebkitMaskImage: `url(${bleed})`,
                                WebkitMaskSize: '100px auto',}
                                :{borderRadius:'5px',width:'150px', margin:'0px', zIndex:'1', marginRight:'2px', bottom:'100px',cursor:'pointer',
                                maskImage: `url(${bleed})`,
                                    maskSize: '150px auto',
                                    maskRepeat: 'no-repeat',
                                    maskPosition: 'center',
                                    WebkitMaskImage: `url(${bleed})`,
                                    WebkitMaskSize: '150px auto',
                                }} />
                            </Sparkles> :

                            <Img key={index} onClick={() => goToCharacter(getCardIndex(card.id, card.name.toLowerCase().replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, ''), card.type), card.name.toLowerCase().replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, ''), card.type)}
                            placeholder={Sleeve} src={card.cardImage} alt={card.name} style={
                              matches?{borderRadius:'5px', width:'100px', margin:'0px',  zIndex:'1', marginRight:'2px',
                              maskImage: `url(${bleed})`,
                              maskSize: '100px auto',
                              maskRepeat: 'no-repeat',
                              maskPosition: 'center',
                              WebkitMaskImage: `url(${bleed})`,
                              WebkitMaskSize: '100px auto',}
                              :{borderRadius:'5px', width:'150px', margin:'0px',  zIndex:'1', marginRight:'2px',cursor:'pointer',
                              maskImage: `url(${bleed})`,
                              maskSize: '150px auto',
                              maskRepeat: 'no-repeat',
                              maskPosition: 'center',
                              WebkitMaskImage: `url(${bleed})`,
                              WebkitMaskSize: '150px auto',}} />
                        )
            })
            }
            </Marquee>

                    <Divider style={{backgroundColor:'white',}}/>
                    <CardTable cardData={cards} />
                    </Grid>
                </Grid>
            </Paper>

        </Grid>
        <Grid container spacing={1}>


            
      
            
         

        </Grid>
        
        <Divider/>
        </Container>
      </Box><Snackbar open={needsUpdate} onClose={() => setExpansionData(oldData)} anchorOrigin={{ vertical:'bottom', horizontal:'center' }}>
          <SnackbarContent
            message="Save this expansion?"
            action={(
              <Grid>
                <Button color="success" variant={"contained"} size="small" style={{margin:'5px'}} onClick={() => handleUpdate()}>
                  Save
                </Button>
                <Button color="error" variant={"contained"} size="small" onClick={() => setExpansionData(oldData)}>
                  Cancel
                </Button>
              </Grid>
            )}
          />

        </Snackbar>
    </div>
  );
};

export default Expansion;