import React from "react";
import Grid from '@mui/material/Grid';
import { Button, Divider, Box, Container, Typography, Select, MenuItem } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import { useCards } from "../providers/cardProvider";
import { useAuth } from '../providers/authProvider';
import _ from 'lodash';
import { firestore } from '../firebase/firebase';
import { useConfirm } from "material-ui-confirm";
import CardTable from "../components/CardTable/CardTable";

const Upcoming = (props) => {
  const confirm = useConfirm();
  const matches = useMediaQuery('(max-width:600px)');
  const { character, equipment, sacrifice, galaxy, god, ritual, locations, virus } = useCards();
  const [cards, setCards] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { currentUser } = useAuth();
  const [expansions] = React.useState(sessionStorage.getItem('expansions') ? JSON.parse(sessionStorage.getItem('expansions')).sort((a, b) => {
    return a.year - b.year;
  }):[]);
  const [expansion, setExpansion] = React.useState('');
  const navigate = useNavigate();

  const changeOrigins = () => {
  confirm({ description: "Add all upcoming cards to "+ expansion + "?" })
  .then(() => {
    setLoading(true);
    let response = cards
    response.forEach(card => {
      card.origin = expansion;
      card.boxImage = expansions.filter(exp => exp.name === expansion)[0].boxImage;
            
    })
  
    //split response up by type
    let characters = response.filter(card => card.type === 'character')
    let equipments = response.filter(card => card.type === 'equipment')
    let sacrifices = response.filter(card => card.type === 'sacrifice')
    let galaxies = response.filter(card => card.type === 'galaxy')
    let gods = response.filter(card => card.type === 'god')
    let rituals = response.filter(card => card.type === 'ritual')
    let location = response.filter(card => card.type === 'location')
    let viruses = response.filter(card => card.type === 'virus')
    

    let newSacs = sacrifice.filter(card => card.origin.length > 0 && !_.isUndefined(card.boxImage))
    sacrifices.forEach(sac => { newSacs.push(sac) })

    let newChars = character.filter(card => card.origin.length > 0 && !_.isUndefined(card.boxImage))
    characters.forEach(char => { newChars.push(char) })

    let newEquip = equipment.filter(card => card.origin.length > 0 && !_.isUndefined(card.boxImage))
    equipments.forEach(equip => { newEquip.push(equip) })

    let newGalaxies = galaxy.filter(card => card.origin.length > 0 && !_.isUndefined(card.boxImage))
    galaxies.forEach(galaxy => { newGalaxies.push(galaxy) })

    let newGods = god.filter(card => card.origin.length > 0 && !_.isUndefined(card.boxImage))
    gods.forEach(god => { newGods.push(god) })

    let newRituals = ritual.filter(card => card.origin.length > 0 && !_.isUndefined(card.boxImage))
    rituals.forEach(ritual => { newRituals.push(ritual) })

    let newLocs = locations.filter(card => card.origin.length > 0 && !_.isUndefined(card.boxImage))
    location.forEach(loc => { newLocs.push(loc) })

    let newViruses = virus.filter(card => card.origin.length > 0 && !_.isUndefined(card.boxImage))
    viruses.forEach(virus => { newViruses.push(virus) })

    firestore.collection('cards').doc('characters').update({
      cards: newChars
    }).then(() => {
        sessionStorage.setItem('characters', JSON.stringify(newChars));
    })

    firestore.collection('cards').doc('sacrifice').update({
        cards: newSacs
    }).then(() => {
        sessionStorage.setItem('sacrifices', JSON.stringify(newSacs));
    })

    firestore.collection('cards').doc('equipment').update({
      cards: newEquip
    }).then(() => {
        sessionStorage.setItem('equipments', JSON.stringify(newEquip));
    })

    firestore.collection('cards').doc('galaxy').update({
      cards: newGalaxies
    }).then(() => {
        sessionStorage.setItem('galaxies', JSON.stringify(newGalaxies));
    })

    firestore.collection('cards').doc('god').update({
      cards: newGods
    }).then(() => {
        sessionStorage.setItem('gods', JSON.stringify(newGods));
    })

    firestore.collection('cards').doc('ritual').update({
      cards: newRituals
    }).then(() => {
        sessionStorage.setItem('rituals', JSON.stringify(newRituals));
    })

    firestore.collection('cards').doc('location').update({
      cards: newLocs
    }).then(() => {
        sessionStorage.setItem('locations', JSON.stringify(newLocs));
    })

    firestore.collection('cards').doc('virus').update({
      cards: newViruses
    }).then(() => {
        sessionStorage.setItem('viruses', JSON.stringify(newViruses));
    })



    setLoading(false)
  })
  .catch(() => {
    /* ... */
  });
}

  React.useEffect(() => {
  
        setLoading(true);
        let response = [];
        let cards2 = []
        if(!sessionStorage.getItem('characters'))
          {
            response = [...response, ...character.filter(card => card.origin.length <=0)]
            setCards(response);
            
          } else {
            response = [...response, ...JSON.parse(sessionStorage.getItem('characters')).filter(card => card.origin.length <=0)];
            cards2 = [...response]
            setCards(cards2);
          }
        if(!sessionStorage.getItem('equipments'))
          {
            response = [...response, ...equipment.filter(card => card.origin.length <=0)]
            setCards(response);
            
          } else {
            response = [...response, ...JSON.parse(sessionStorage.getItem('equipments')).filter(card => card.origin.length <=0)];
            cards2 = [...response]
            setCards(cards2);
          }
          if(!sessionStorage.getItem('sacrifices'))
          {
            response = [...response, ...sacrifice.filter(card => card.origin.length <=0)]
            setCards(response);
            
          } else {
            response = [...response, ...JSON.parse(sessionStorage.getItem('sacrifices')).filter(card => card.origin.length <=0)];
            cards2 = [...response]
            setCards(cards2);
          }
          if(!sessionStorage.getItem('locations'))
          {
            response = [...response, ...locations.filter(card => card.origin.length <=0)]
            setCards(response);
            
          } else {
            response = [...response, ...JSON.parse(sessionStorage.getItem('locations')).filter(card => card.origin.length <=0)];
            cards2 = [...response]
            setCards(cards2);
          }
          if(!sessionStorage.getItem('galaxies'))
          {
            response = [...response, ...galaxy.filter(card => card.origin.length <=0)]
            setCards(response);
            
          } else {
            response = [...response, ...JSON.parse(sessionStorage.getItem('galaxies')).filter(card => card.origin.length <=0)];
            cards2 = [...response]
            setCards(cards2);
          }
          if(!sessionStorage.getItem('gods'))
          {
            response = [...response, ...god.filter(card => card.origin.length <=0)]
            setCards(response);
            
          } else {
            response = [...response, ...JSON.parse(sessionStorage.getItem('gods')).filter(card => card.origin.length <=0)];
            cards2 = [...response]
            setCards(cards2);
          }
          if(!sessionStorage.getItem('rituals'))
          {
            response = [...response, ...ritual.filter(card => card.origin.length <=0)]
            setCards(response);
            setLoading(false)
          } else {
            response = [...response, ...JSON.parse(sessionStorage.getItem('rituals')).filter(card => card.origin.length <=0)];
            cards2 = [...response]
            setCards(cards2);
            setLoading(false)
          }
        


  // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [])
  
  React.useEffect(() => {
  

} , [matches])


  return (
    <div>
        
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              '#075d8e',
            flexGrow: 1,
            width: '100%',
            marginTop:"10%",
          }}
        >
        <Container maxWidth="lg" sx={{ p: 4 }}>
        {loading ? <Skeleton variant={'circular'} width={'70px'} height={'70px'} sx={{marginLeft:'46%', marginBottom:'5%'}}></Skeleton> : null}
        {loading ? <Typography variant="h5" component="h2" gutterBottom>Loading...</Typography> : <Typography variant="h5" component="h2" gutterBottom>Upcoming Cards</Typography>}
        <Grid container spacing={1}>
            <Grid item>
                <Button variant="contained" color="primary" onClick={() => navigate(-1)}>Back</Button>
                {!_.isEmpty(currentUser) ? <Button variant="contained" color="secondary" style={{margin:'5px'}} 
                disabled={expansion===''}
                onClick={() => changeOrigins()}>Add All to Expansion</Button>
            : null}

{!_.isEmpty(currentUser) ? 
              <Grid container>
                <Typography variant="body1"  gutterBottom style={{margin:'5px'}}>Select Expansion</Typography>
                <Select value={expansion} fullWidth onChange={(e) => setExpansion(e.target.value)} style={{margin:'5px'}}>
                  <MenuItem value={''}>Select Expansion</MenuItem>
                  {expansions.map((expansion, index) => {
                    return (
                      <MenuItem value={expansion.name} key={index}>{expansion.name}</MenuItem>
                    )
                  })}

                </Select>
              </Grid>
            : null}
            </Grid>

        </Grid>
        <Divider style={{margin:'10px'}} />
        
        <Grid container spacing={1}>
             {loading && 
             <Skeleton variant="rect" width={'1400px'} height={'1500px'}></Skeleton>}   
            {!loading && cards.length > 0 &&
            <CardTable cardData={cards}/>
            }
            
            {!loading && cards.length <=0 &&
            <Typography variant="h5" component="h2" gutterBottom>There are currently no cards made that aren't printed..</Typography>
            }
    
        </Grid>
        <Divider/>
        </Container>
      </Box>
    </div>
  );
};

export default Upcoming;