import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { firestore, removeFile, uploadFile } from '../firebase/firebase';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Button, Divider, TextField, Typography, FormControl, MenuItem, Select, Snackbar, SnackbarContent, FormControlLabel, Checkbox } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import useMediaQuery from '@mui/material/useMediaQuery';
import Img from 'react-cool-img';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import Sparkles from "../components/Sparkles";
import _ from 'lodash';
import { useCards } from '../providers/cardProvider';
import Meta from '../providers/meta';
import ICON from '../img/icon.png';
import { useConfirm } from "material-ui-confirm";
import CardButtons from '../components/CardButtons/CardButtons';
import CardImage from '../components/CardImage/CardImage';
import CardName from '../components/CardDetails/CardName';
import CardAbility from '../components/CardDetails/CardAbility';
import CardFranchise from '../components/CardDetails/CardFranchise';
import CardDescription from '../components/CardDetails/CardDescription';
import CardHeader from '../components/CardDetails/CardHeader';
import LocationDetails from '../components/CardDetails/LocationDetails';
import VariantList from '../components/CardDetails/VariantList';
import BrushIcon from '@mui/icons-material/Brush';

const Card = (props) => {
    const confirm = useConfirm();
    const navigate = useNavigate();
    const { character, equipment, locations, virus, ritual, sacrifice, god, galaxy, expansions, logos } = useCards();
    
    const [edit, setEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [characterData, setCharacterData] = React.useState([]);
    const [oldData, setOldData] = React.useState([]);
    const [needsUpdate, setNeedsUpdate] = React.useState(false);
    //const [originData, setOriginData] = React.useState({});
    //get url
    const url = window.location.href;
    //get card name from url
    const cardName = url.substring(url.indexOf(props.cardType) + props.cardType.length + 1, url.lastIndexOf('-'));
    const cardNumber = Number(url.substring(url.lastIndexOf('-') + 1));
    let path = cardName;
    let site_name = '';
    let description = '';
    let color = '';

    function getExpansionYear(expansion) {

        let expansionYear = expansions.length > 0 && expansions.filter(item => item.name === expansion).length > 0 ? expansions.filter(item => item.name === expansion)[0].year : 0;
        return expansionYear || 0;
      
    }

    const matches = useMediaQuery('(max-width:600px)');

    function getCardList(cardType) {
      switch(cardType) {
        case 'character': return character;
        case 'equipment': return equipment;
        case 'location': return locations;
        case 'virus': return virus;
        case 'ritual': return ritual;
        case 'sacrifice': return sacrifice;
        case 'god': return god;
        case 'galaxy': return galaxy;
        default: return [];
      }
    }

    //useEffect for media query
    React.useEffect(() => {
  
    } , [matches])

    const goToArtist = (name) => {
        navigate(`/artists/${name}`, { state: { id: name},
      });
    }

    React.useEffect(() => {
          //if oldData and CharacterData are different, update firestore
          if(JSON.stringify(characterData) !== JSON.stringify(oldData)){
            setNeedsUpdate(true);
        } else {
            setNeedsUpdate(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [characterData])

    React.useEffect(() => {
      if(characterData !== []){
        if(!sessionStorage.getItem(props.cardTypes) ){
            setLoading(true);
            let response = [];
            response = getCardList(props.cardType)
            
            setCharacterData(response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '').replace(/[.:'"]/g, '') === path )[cardNumber]);
            setOldData(response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '').replace(/[.:'"]/g, '') === path)[cardNumber]);
            sessionStorage.setItem(props.cardTypes, JSON.stringify(response));
            if(props.cardType === 'character'){
              // eslint-disable-next-line react-hooks/exhaustive-deps
              site_name = 'ATK: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ATK+'       DEF: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].DEF
              // eslint-disable-next-line react-hooks/exhaustive-deps
              description = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.length > 0 ? response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].description.replace(/"/g, '')+'\n\n'+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.replace(/"/g, '') : response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].description.replace(/"/g, '')
              // eslint-disable-next-line react-hooks/exhaustive-deps
              color = '#ff0000';
              
          } else if(props.cardType === 'equipment'){
              site_name = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].eType;
              description = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.length > 0 ? response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].description.replace(/"/g, '')+'\n\n'+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.replace(/"/g, '') : response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].description.replace(/"/g, '');
              color ="#009617";
          } else if(props.cardType === 'sacrifice'){
              site_name = 'ATK: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ATK+'       DEF: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].DEF
              description = 'Level '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].Level+' Sacrifice\n\n'+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].description.replace(/"/g, '');
              color ="#0043c9";
          } else if(props.cardType === 'galaxy'){
              site_name = 'ATK: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ATK+'       DEF: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].DEF
              description = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].materialCount+' '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].Franchise+' Cards Required for Materials\n\n'+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.replace(/"/g, '');
              color ="#3f00d1";
          } else if(props.cardType === 'god'){
              site_name = 'ATK: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ATK+'       DEF: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].DEF
              description = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].summonRequirements.replace(/"/g, '')+'\n\n'+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.replace(/"/g, '');
              color ="#242424";
          } else if(props.cardType === 'ritual'){
              site_name = 'ATK: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ATK+'       DEF: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].DEF
              description = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].summonRequirements.replace(/"/g, '')+'\n\n'+response.filter(card => card.name.toLowerCase().replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.replace(/"/g, '');
              color ="#1e233b";
          } else if(props.cardType === 'location'){
              site_name = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].FranchiseBuff+': '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].buff +'  -  '+ response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].FranchiseDebuff+': '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].debuff
              description = 'Locked for '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].turns+' turns\n\n'+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.replace(/"/g, '');
              color ="#c9c9c9";
          } else if(props.cardType === 'virus'){
              site_name = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].vType+' Virus'
              description = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].turns+' Turns Until Auto Cure\n\n'+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.replace(/"/g, '');
              color ="#9000ff";
          }
            setLoading(false);
          
          } else if(!needsUpdate){
            setLoading(true);
            let response = JSON.parse(sessionStorage.getItem(props.cardTypes))
      
            setCharacterData(response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path)[cardNumber]);
            setOldData(response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path)[cardNumber]);
            if(props.cardType === 'character'){
              site_name = 'ATK: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ATK+'       DEF: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].DEF
              description = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.length > 0 ? response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].description.replace(/"/g, '')+'\n\n'+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.replace(/"/g, '') : response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].description.replace(/"/g, '')
              color = '#ff0000';
          } else if(props.cardType === 'equipment'){
              site_name = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].eType;
              description = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.length > 0 ? response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].description.replace(/"/g, '')+'\n\n'+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.replace(/"/g, '') : response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].description.replace(/"/g, '');
              color ="#009617";
          } else if(props.cardType === 'sacrifice'){
              site_name = 'ATK: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ATK+'       DEF: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].DEF
              description = 'Level '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].Level+' Sacrifice\n\n'+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].description.replace(/"/g, '');
              color ="#0043c9";
          } else if(props.cardType === 'galaxy'){
              site_name = 'ATK: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ATK+'       DEF: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].DEF
              description = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].materialCount+' '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].Franchise+' Cards Required for Materials\n\n'+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.replace(/"/g, '');
              color ="#3f00d1";
          } else if(props.cardType === 'god'){
              site_name = 'ATK: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ATK+'       DEF: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].DEF
              description = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].summonRequirements.replace(/"/g, '')+'\n\n'+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.replace(/"/g, '');
              color ="#242424";
          } else if(props.cardType === 'ritual'){
              site_name = 'ATK: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ATK+'       DEF: '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].DEF
              description = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].summonRequirements.replace(/"/g, '')+'\n\n'+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.replace(/"/g, '');
              color ="#1e233b";
          } else if(props.cardType === 'location'){
              site_name = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].FranchiseBuff+': '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].buff +'  -  '+ response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].FranchiseDebuff+': '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].debuff
              description = 'Locked for '+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].turns+' turns\n\n'+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.replace(/"/g, '');
              color ="#c9c9c9";
          } else if(props.cardType === 'virus'){
              site_name = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].vType+' Virus'
              description = response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].turns+' Turns Until Auto Cure\n\n'+response.filter(card => card.name.toLowerCase().replace(/[.:'"]/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === path )[cardNumber].ability.replace(/"/g, '');
              color ="#9000ff";
          }
            setLoading(false);
        }} else {
          setLoading(false);
        }
        
    } , [loading,cardNumber])


    //handle update characterData to document
    const handleUpdate = (e) => {
        setLoading(true);

        let response = []
        response = getCardList(props.cardType)
        
        response = response.filter(card => card.id !== characterData.id)
        response.push(characterData)


          firestore.collection('cards').doc(props.firebaseType).update({
              cards: response
          }).then(() => {
              
              sessionStorage.setItem(props.cardTypes, JSON.stringify(response));
              setOldData(characterData);
              setNeedsUpdate(false);
              setLoading(false);
          })
      
    }

    const removeImage = async (url) => {

      let query = await removeFile(url);

      return query.fileDeleted;
  }


  //handle replacing image
  const handleImage = async (e) => {
    setLoading(true);
    let response = []
    response = getCardList(props.cardType)
    response = response.filter(card => card.id !== characterData.id)
    //grab cardImage and delete it from storage
    removeImage(characterData.cardImage);
    //change image name
    var name = (characterData.foil || characterData.cloudyfoil || characterData.freenian || characterData.promo) ? characterData.name+'_FOIL' : characterData.name
    name += '_'+characterData.origin+'_'+characterData.artist+'_'+new Date().getTime()
    //eslint-disable-next-line
    name = name.replace(/[\(\)]/g, '')
    //strip # from name
    name = name.replace(/#/g, '')
    var type2 = null
    if (characterData.type === 'Character') {
        type2 = 'characters'
    } else if (characterData.type === 'Equipment') {
        type2 = 'equipment'
    } else if (characterData.type === 'Sacrifice') {
        type2 = 'sacrifice'
    } else if (characterData.type === 'Location') {
        type2 = 'location'
    } else if (characterData.type === 'Virus') {
        type2 = 'virus'
    } else if (characterData.type === 'Galaxy') {
        type2 = 'galaxy'
    } else if (characterData.type === 'God') {
        type2 = 'god'
    } else if (characterData.type === 'Ritual') {
        type2 = 'ritual'
    }
    let path = 'cards/'+type2+'/' + name + '.png';

    //upload new image
    let image = await uploadFile(path, e.target.files[0]);

    //update characterData
    characterData.cardImage = image.url;
    response.push(characterData)
    //update firestore
    firestore.collection('cards').doc(props.firebaseType).update({
        cards: response
    }).then(() => {

        sessionStorage.setItem(props.cardTypes, JSON.stringify(response));
        setLoading(false);
    })
  }

    const handleDelete = async (e) => {
      //alert yes or no?
      confirm({ description: "Deleting a card is permanent!" })
      .then(() => {
        setLoading(true);

        let response = []
        response = getCardList(props.cardType)
        response = response.filter(card => card.id !== characterData.id)
  
        //grab cardImage and delete it from storage
        removeImage(characterData.cardImage);
  
        firestore.collection('cards').doc(props.firebaseType).update({
            cards: response
        }).then(() => {
            
            sessionStorage.setItem(props.cardTypes, JSON.stringify(response));
            navigate(-1);
            setLoading(false);
        })
      })
      .catch(() => {
        /* ... */
      });


    } 
            

    function grabLogoImage(name) {
      let logos = JSON.parse(sessionStorage.getItem('logos'))
      if(logos){
        let logo = logos.filter(logo => logo.name === name)[0]
        
        if(logo){
          return logo.image;
        } else {
          return null;
        }
      } 

      
    }

    function grabOriginImage(name) {
      let logos = JSON.parse(sessionStorage.getItem('expansions'))
  
      if(logos){
        let logo = logos.filter(logo => logo.name === name)[0]
        
        if(logo){
          return logo.boxImage;
        } else {
          return null;
        }
      } 

      
    }

    //get all cards of the same cardName into a list from sessionStorage
    function getVariants() {
      let response = []
      if (characterData.type === 'character') {
        response = JSON.parse(sessionStorage.getItem('characters'))
        response = response.filter(card => card.name === characterData.name && card.id !== characterData.id)
      } else if (characterData.type === 'equipment') {
        response = JSON.parse(sessionStorage.getItem('equipments'))
        response = response.filter(card => card.name === characterData.name && card.id !== characterData.id)
      } else if (characterData.type === 'sacrifice') {
        response = JSON.parse(sessionStorage.getItem('sacrifices'))
        response = response.filter(card => card.name === characterData.name && card.id !== characterData.id)
      } else if (characterData.type === 'location') {
        response = JSON.parse(sessionStorage.getItem('locations'))
        response = response.filter(card => card.name === characterData.name && card.id !== characterData.id)
      } else if (characterData.type === 'virus') {
        response = JSON.parse(sessionStorage.getItem('viruses'))
        response = response.filter(card => card.name === characterData.name && card.id !== characterData.id)
      } else if (characterData.type === 'galaxy') {
        response = JSON.parse(sessionStorage.getItem('galaxies'))
        response = response.filter(card => card.name === characterData.name && card.id !== characterData.id)
      } else if (characterData.type === 'god') {
        response = JSON.parse(sessionStorage.getItem('gods'))
        response = response.filter(card => card.name === characterData.name && card.id !== characterData.id)
      } else if (characterData.type === 'ritual') {
        response = JSON.parse(sessionStorage.getItem('rituals'))
        response = response.filter(card => card.name === characterData.name && card.id !== characterData.id)
      }
      return response
    }

    //get expansiondata from session storage
    const expansionData = expansions.filter(exp=>exp.name === characterData.origin)[0];
    const franchiseData = logos.filter(logo=>logo.name === characterData.Franchise)[0];

    return (<React.Fragment>
        {loading  ?
        <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            '#075d8e',
          margin: "10%",
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="xl" sx={{ p: 4 }}>
        <Grid container spacing={2} >
          <Skeleton variant="rectangular" sx={{ bgcolor: '#00324f' }}  animation="wave" width={2000} height={700} >
          </Skeleton>
          
        </Grid>
          

        </Container>
      </Box>
        
        
         : 
        <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            '#075d8e',
          margin: "10%",
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Meta site_name={site_name} title={characterData.name} 
      description={description} 
      color={color}
      image={characterData.cardImage}/>
        <Container maxWidth="xl" sx={{ p: 2 }}>
        <CardButtons cardData={characterData} franchiseData={franchiseData} handleDelete={handleDelete} edit={edit} setEdit={setEdit} />
        <Paper sx={{padding: 4, backgroundColor:'#36a1df',
          backgroundImage: `url(${ICON})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          height: '100%',
      }}>          
        {characterData.origin.length <= 0 ? <Typography sx={{backgroundColor:'#c22d00', color:'white', borderRadius:'10px', margin:'10px'}}>This is an UPCOMING card and is <b>NOT </b>printed yet.</Typography> : null}   
        {characterData.banned ? <Typography sx={{backgroundColor:'yellow', color:'black', borderRadius:'10px', margin:'10px'}}>This is a BANNED card: <b>{characterData.bannedReason}</b></Typography> : null}               
          <Grid container spacing={2} >
            {/* Recent Deposits */}
            {matches ? 
             <Grid item xs={12} md={6} lg={6}  >
                <CardImage cardData={characterData} edit={edit} replaceImage={handleImage}/>
            </Grid>
            
            : 
            <Grid item xs={12} md={6} lg={6}  >
              <CardImage cardData={characterData} edit={edit} replaceImage={handleImage}/>
            </Grid>
            }
            
            <Grid item  md={6} lg={6} xs={12} style={{position:'relative'}} >
            <Paper sx={{backgroundColor:'rgba(0, 0, 0, 0.5)', color:'white', paddingBottom:'20%', marginTop:'5%',}}>
                <Grid container spacing={2}>
                  <Grid item xs={2} md={2} lg={2}  >
                    {characterData.origin.length > 0 ?
                      <Button startIcon={<img src={expansionData.boxImage} onClick={() => navigate('/expansions/'+characterData.origin
                      .toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,'')
                      )}
                        alt={characterData.Franchise} 
                        style={matches ? { width: 36, height: 38, margin:'-12px' }: { width: 96, height: 72, margin:'-22px' }}/>}
                        style={{marginLeft:'40px'}}
                        variant="text"/> : null}
                      </Grid>
                    <CardName cardData={characterData} setCardData={setCharacterData} cardType={props.cardType}
                    edit={edit}/>
                  {props.cardType === 'character' ?
                  <Grid item xs={2} md={2} lg={2}  >
                      <Button startIcon={<img src={franchiseData.image} onClick={() => navigate('/franchises/franchise?name='+characterData.Franchise)}
                        alt={characterData.Franchise} 
                        style={matches ? { width: 36, height: 38, margin:'-12px' }: { width: 96, height: 72, margin:'-22px' }}/>} variant="text"/>
                      </Grid>: null}
                </Grid>
                <Grid container spacing={1}  >
                    
                    <Grid container spacing={1} style={{marginBottom: !characterData.hybrid && !characterData.cloudyfoil && !characterData.foil ? '40px' : '0px'}} 
                        direction="row"
                        alignItems="center"
                        justifyContent="center" >

                
 
                    {(characterData.hybrid) || (props.cardType === 'character' && edit )? 
                      <Grid item xs={2}  >
                      {edit ? 
                          <FormControlLabel control={<Checkbox   
                            checked={characterData.hybrid}
                            onChange={(e) => setCharacterData({...characterData, hybrid: e.target.checked})}
                          />} label="Hybrid" />
                        :
                        <Sparkles color='#008000'>
                            <Typography style={{backgroundColor:'lightGreen', borderRadius:'5px', padding:'2px 20px 2px 20px', color:'darkGreen'}}><b>Hybrid</b></Typography>
                        </Sparkles>}
                      </Grid>: null}

                      {(characterData.cloudyfoil) || (props.cardType === 'character' && edit )? 
                      <Grid item xs={3}  >
                      {edit ? 
                          <FormControlLabel control={<Checkbox   
                            checked={characterData.cloudyfoil}
                            onChange={(e) => setCharacterData({...characterData, cloudyfoil: e.target.checked})}
                          />} label="Cloudy Foil" />
                        :
                        <Sparkles color='#FFFFFF'>
                            <Typography style={{backgroundColor:'#ff546e', borderRadius:'5px', padding:'2px 20px 2px 20px', color:'white'}}><b>Cloudy Foil</b></Typography>
                        </Sparkles>}
                      </Grid>: null}

                      {(characterData.freenian) || (props.cardType === 'character' && edit )? 
                      <Grid item xs={3}  >
                      {edit ? 
                          <FormControlLabel control={<Checkbox   
                            checked={characterData.freenian}
                            onChange={(e) => setCharacterData({...characterData, freenian: e.target.checked})}
                          />} label="Freenian" />
                        :
                        <Sparkles color='#000000'>
                            <Typography style={{backgroundColor:'#000000', borderRadius:'5px', padding:'2px 20px 2px 20px', color:'white'}}><b>Freenian</b></Typography>
                        </Sparkles>}
                      </Grid>: null}
                      
                      {(characterData.promo) || (props.cardType === 'character' && edit )? 
                      <Grid item xs={3}  >
                      {edit ? 
                          <FormControlLabel control={<Checkbox   
                            checked={characterData.promo}
                            onChange={(e) => setCharacterData({...characterData, promo: e.target.checked})}
                          />} label="Promo" />
                        :
                        <Sparkles color='#3b7fff'>
                            <Typography style={{backgroundColor:'#0059ff', borderRadius:'5px', padding:'2px 20px 2px 20px', color:'white'}}><b>Promo</b></Typography>
                        </Sparkles>}
                      </Grid>: null}

                      {characterData.foil || edit ? 
                      <Grid item xs={2}  >
                        {edit ? 
                          <FormControlLabel control={<Checkbox   
                            checked={characterData.foil}
                            onChange={(e) => setCharacterData({...characterData, foil: e.target.checked})}
                          />} label="Foil" />
                        :
                          <Sparkles>
                              <Typography style={{backgroundColor:'lightBlue', borderRadius:'5px', padding:'2px 20px 2px 20px', color:'darkBlue'}}><b>Foil</b></Typography>
                          </Sparkles>}
                      </Grid>  : null}   

                      {(characterData.banned) || ( edit )? 
                      <Grid item xs={12} style={{marginTop:'10px'}} >
                      {edit ? <React.Fragment>
                          <FormControlLabel control={<Checkbox   
                            checked={characterData.banned}
                            onChange={(e) => setCharacterData({...characterData, banned: e.target.checked})}
                          />} label="Banned" />
                        <TextField 
                            value={characterData.bannedReason}
                            onBlur={(e) => { setCharacterData({...characterData, bannedReason: e.target.value})}}
                            fullWidth
                            disabled={props.saving || !characterData.banned}
                            name="bannedReason"
                            required
                            size="small"
                            variant="outlined"
                            label="Reason"
                        />
                        </React.Fragment>
                        :
                            <Typography ><b style={{backgroundColor:'yellow', borderTopLeftRadius:'5px', borderBottomLeftRadius: '5px', padding:'2px 20px 2px 20px', color:'black'}}>BANNED</b>
                            <b style={{backgroundColor:'black', color:'white', borderBottomRightRadius:'5px', borderTopRightRadius:'5px', padding:'2px 20px 2px 20px' }}>{characterData.bannedReason}</b></Typography>
                        }
                      </Grid>: null}                                

                    </Grid> 
                  
                </Grid>
            <Grid container spacing={2} sx={{marginTop:'0px'}}>
              
              <Grid item xs={6} md={6} lg={6}   >
                  {edit ? 
                    <FormControl fullWidth style={{margin:'20px'}}>
                    <Select
                        name="Origin"
                        value={characterData.origin}
                        label="Origin"
                        variant='outlined'
                        onChange={(e) => setCharacterData({...characterData, origin: e.target.value,
                        boxImage: grabOriginImage(e.target.value)
                        })}
                    ><MenuItem value="">None</MenuItem>

                        {JSON.parse(sessionStorage.getItem('expansions')).map((logo) => {
                            return (
                                <MenuItem key={logo.name} value={logo.name}><img src={logo.boxImage} style={{width:'48px'}} 
                                alt={logo.name+ ' image'}/>{logo.name}</MenuItem>
                            )
                        })}
                                
                    </Select>
                    </FormControl>
                  : characterData.origin.length > 0 ?<Typography variant={'body1'} style={matches ? {fontSize:'12px'} : {}}>
                      
                  <b>First Printed: </b>{characterData.origin} ({getExpansionYear(characterData.origin)}) </Typography>
                  : null

                  
                }
              </Grid>
              <Grid item xs={6} md={6} lg={6}  >
                {props.cardType === 'character' ? 
                <CardFranchise cardData={characterData} setCardData={setCharacterData} edit={edit} grabLogoImage={grabLogoImage} /> 
                : null}
              </Grid>
            </Grid>
              
              
              <Divider sx={{marginBottom:'20px'}}/>

              <CardHeader cardData={characterData} setCardData={setCharacterData} edit={edit} cardType={props.cardType} />

              
              

              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}  >
                  <CardDescription cardData={characterData} setCardData={setCharacterData} edit={edit} cardType={props.cardType} grabLogoImage={grabLogoImage}/>
                </Grid>
                {props.cardType ==='location' ?<Grid item xs={12} md={12} lg={12}  >
                  <LocationDetails cardData={characterData} setCardData={setCharacterData} edit={edit} cardType={props.cardType} grabLogoImage={grabLogoImage}/>
                </Grid>:null}
              </Grid>

              

              
              <Grid container spacing={2} >
              <Grid item xs={2} md={2} lg={2}  ></Grid>

              <Grid item xs={8} md={8} lg={8}  >
                {characterData.hybrid ? 
                  <Paper style={{backgroundColor: 'green', color:'white', padding:'10px', marginBottom:'5%'}}>
                    
                        <Grid item xs={12} md={12} lg={12}   >
                            <Typography variant={matches ? 'body1' : 'h4'} style={matches ? {fontSize:'12px'} : {}}><StarIcon style={matches ? {fontSize:'12px'} : {}}/> HYBRID ABILITY</Typography>
                        </Grid>
                        <Grid item  md={6} lg={6} xs={12}  >
                            <Typography variant={matches ? 'body1' : 'h4'}></Typography>
                        </Grid>
                      
                  
                  <Divider style={{backgroundColor:'white'}}></Divider>

                  <Typography variant={'body1'} style={matches ? {fontSize:'12px'} : {}}>  <span
                dangerouslySetInnerHTML={{
                  __html: !characterData.hybridAbility ? null : characterData.hybridAbility.replace(/spell counter/gi, (match) => `<img src=${ICON} alt='spellcounter' width='16px' style=${{marginRight:'6px'}}/>&nbsp;<b>${match.toUpperCase()}</b>`)
                  .replace(/heads:/gi, (match) => `<br/><b>Heads:</b>`).replace(/tails:/gi, (match) => `<br/><b>Tails:</b>`),

                }}
              /> </Typography>
                  
                  </Paper> : null}
                  
                  <CardAbility cardData={characterData} edit={edit} setCharacterData={setCharacterData} cardType={props.cardType}/>
              </Grid>
              <Grid item xs={2} md={2} lg={2}  ></Grid>


                
                
                
              </Grid>
              </Paper>
            </Grid>
           
            <Grid item md={6} lg={6} xs={12}>
              
            {edit ? 
              <TextField InputProps={{ style: { fontSize: '1rem'} }} size="small"
                label="Artist" variant="outlined" value={characterData.artist} onChange={(e) => setCharacterData({...characterData, artist: e.target.value})} />
            : 
            <Grid container>
              <Grid item xs={12}>
                <BrushIcon style={{marginRight:'5px',}}/>
                <Typography onClick={()=>{goToArtist(characterData.artist)}} style={matches ? {fontSize:'12px',cursor:'pointer'} : {cursor:'pointer'}} component='b'>Art By {characterData.artist}</Typography>
              </Grid>
              
            </Grid>
            
            
            }
                
            </Grid>

            
            
            
           
           
          </Grid>
          
        </Paper>
        

          {getVariants().length >0 ? <VariantList variants={getVariants()}/> : null}

       
        <Grid style={{backgroundColor:'#002236', borderRadius:'5px', marginTop:'5%' }} >
          {(!_.isUndefined(characterData.boxImage)) ? <Img onClick={() => navigate('/expansions/'+characterData.origin
          //to lower case and replace spaces with dashes and strip out special characters
          .toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,'')
          )}
          src={expansionData.boxImage}  loading={<CircularProgress />} alt={characterData.origin} style={matches ? {width:'100px', cursor:'pointer'}: {width:'400px', cursor:'pointer'}}/>
          : <HelpOutlineIcon/>}
          {characterData.origin.length <= 0 ? <Typography>This is an UPCOMING card and not printed yet.</Typography> : <Typography>This card was first printed in the {characterData.origin} in {getExpansionYear(characterData.origin)}
          </Typography>}
        </Grid>
        </Container>
      </Box>
        
        }<Snackbar open={needsUpdate} onClose={() => setCharacterData(oldData)} anchorOrigin={{ vertical:'bottom', horizontal:'center' }}>
          <SnackbarContent
            message="Save this card?"
            action={(
              <Grid>
                <Button color="success" variant={"contained"} size="small" style={{margin:'5px'}} onClick={() => handleUpdate()}>
                  Save
                </Button>
                <Button color="error" variant={"contained"} size="small" onClick={() => setCharacterData(oldData)}>
                  Cancel
                </Button>
              </Grid>
            )}
          />

        </Snackbar></React.Fragment>
    );
}
export default Card;