import * as React from 'react';
import { firestore } from '../../firebase/firebase';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
//import { useLocation } from 'react-router-dom';
import { Divider, Typography, TextField, Button, Checkbox } from '@mui/material';
import { FormControl, MenuItem, Select, Input } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import _ from 'lodash';

const EquipmentCard = (props) => {

    const [loading, setLoading] = React.useState(true);
    const [originData, setOriginData] = React.useState([]);
    const [equipmentData, setEquipmentData] = React.useState(
        sessionStorage.getItem('equipmentData') ? JSON.parse(sessionStorage.getItem('equipmentData')) : props.equipmentData);

    const matches = useMediaQuery('(max-width:600px)');

    const handleSetEquipmentData = (e, value) => {
        var newData = equipmentData
        newData[e.target.name] = value
        setEquipmentData({
            ...equipmentData,
            [e.target.name]: value
        })
        sessionStorage.setItem('equipmentData', JSON.stringify(newData));
        props.updateData(newData);
    }

    //useEffect for media query
    React.useEffect(() => {
  
    } , [matches])

    React.useEffect(() => {
        setLoading(true);
        let response = [];
        if(!sessionStorage.getItem('expansions')){
        firestore.collection('expansions').orderBy('name', 'asc').get()
            .then(querySnapshot => {
                response = querySnapshot.docs.map((doc) => {
                        return {
                        ...doc.data()
                        }
    
                    })
    
                    setOriginData(response);
                    setLoading(false);
                }).catch (e => {
                    
                    setLoading(false);
                });
        } else {
            setOriginData(JSON.parse(sessionStorage.getItem('expansions')));
            setLoading(false);
        }
    } , [])

    const handleSubmit = (e) => {
        setLoading(true);
        
        props.saveData()
    }

    return (
    
    <React.Fragment>
        <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            '#075d8e',
          margin: "10%",
          overflow: 'hidden',
        }}
        >
        <Container  sx={{ p: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Paper elevation={2} style={{padding:'5px', height:'100%'}}>
                    <Typography align="center" variant='body1' style={{lineHeight:'2'}}> <b>Upload Card Image: </b> </Typography>
                    <Divider />
                        <Input
                            id="uploadImage"
                            type='file'
                            accept="image/*"
                            name="cardImage"
                            disabled={props.saving && loading}
                            onChange={(e) => { handleSetEquipmentData(e, e.target.files[0])}}
                            required/>
                    {equipmentData.cardImage !== '' ? <img style={{width: '350px'}}
                    src={!_.isUndefined(equipmentData.cardImage) && equipmentData.cardImage instanceof File ? URL.createObjectURL(equipmentData.cardImage) : equipmentData.cardImage} 
                    alt="Uploaded"  /> : null}
                    </Paper>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Paper elevation={2} style={{padding:'5px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Card Name: </b> </Typography>
                            </Grid>

                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                <TextField 
                                    value={equipmentData.name}
                                    onChange={(e) => { handleSetEquipmentData(e, e.target.value) }}
                                    fullWidth
                                    disabled={props.saving}
                                    required
                                    name="name"
                                    size="small"
                                    variant="outlined"
                                    label="Name"
                                
                                />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Type: </b> </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={equipmentData.eType}
                                    label="Type"
                                    name="eType"
                                    variant='outlined'
                                    disabled={props.saving}
                                    required
                                    onChange={(e) => { handleSetEquipmentData(e, e.target.value)}}
                                >
                                    <MenuItem value="Equip">Equip</MenuItem>
                                    <MenuItem value="Activate">Activate</MenuItem>
                                    <MenuItem value="Quickplay">Quickplay</MenuItem>
                                    <MenuItem value="Continuous">Continuous</MenuItem>
                                    <MenuItem value="Ritual">Ritual</MenuItem>
                                    
                                </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Foil/Holo?: </b> </Typography>
                            </Grid>

                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <Checkbox style={{bottom:'7px'}}
                                    checked={equipmentData.foil}
                                    onChange={(e) => { handleSetEquipmentData(e, e.target.checked)}}
                                    disabled={props.saving}
                                    name="foil"
                                    size="small"

                                />
                            </Grid>
                            <Grid item xs={12}/>
                            <Grid item xs={1}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2', marginRight:'30px'}}> <b>Banned: </b> </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Checkbox style={{bottom:'8px'}}
                                    checked={equipmentData.banned}
                                    onChange={(e) => { handleSetEquipmentData(e, e.target.checked)}}
                                    disabled={props.saving}
                                    name="banned"
                                    size="small"

                                />
                            </Grid>
                            <Grid item xs={10}>
                                <TextField 
                                    value={equipmentData.bannedReason}
                                    onChange={(e) => { handleSetEquipmentData(e, e.target.value)}}
                                    fullWidth
                                    disabled={props.saving || !equipmentData.banned}
                                    name="bannedReason"
                                    required
                                    size="small"
                                    variant="outlined"
                                    label="Reason"
                                
                                />
                            </Grid>
                            <Grid item xs={12}/>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Description:  <small>(if applicable)</small></b> </Typography>
                            </Grid>

                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                <TextField 
                                    value={equipmentData.description}
                                    onChange={(e) => { handleSetEquipmentData(e, e.target.value)}}
                                    fullWidth
                                    disabled={props.saving}
                                    size="small"
                                    name="description"
                                    multiline
                                    rows={5}
                                    placeholder='Enter Description...'
                                    variant="outlined"
                                    
                                
                                />
                            </Grid>

                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Ability: </b> </Typography>
                            </Grid>

                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                <TextField 
                                    value={equipmentData.ability}
                                    onChange={(e) => { handleSetEquipmentData(e, e.target.value)}}
                                    fullWidth
                                    name="ability"
                                    size="small"
                                    multiline
                                    required
                                    disabled={props.saving}
                                    rows={5}
                                    placeholder='Enter Ability...'
                                    variant="outlined"
                                    
                                
                                />
                            </Grid>

                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Artist: </b> </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                <TextField 
                                    value={equipmentData.artist}
                                    onChange={(e) => { handleSetEquipmentData(e, e.target.value)}}
                                    fullWidth
                                    name="artist"
                                    size="small"
                                    disabled={props.saving}
                                    required
                                    variant="outlined"
                                    label="Artist"
                                
                                />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Origin: </b> </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={equipmentData.origin}
                                    label="Origin"
                                    name="origin"
                                    variant='outlined'
                                    disabled={props.saving}
                                    required
                                    onChange={(e) => { handleSetEquipmentData(e, e.target.value)}}
                                >
                                    {originData.map((logo) => {
                                        return (
                                            <MenuItem key={logo.name} value={logo.name}>
                                                {logo.boxImage ? <img src={logo.boxImage} style={{width:'48px'}} alt={logo.name+ ' image'}/> : <HelpOutlineIcon/>}
                                                &nbsp;{logo.name} {logo.year ? '('+logo.year+')' : null}</MenuItem>
                                        )
                                    })}
                                    
                                    
                                </Select>
                                </FormControl>
                            </Grid>
                            
                            
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Button variant="filled" onClick={() => handleSubmit()} disabled={props.saving}
            style={{backgroundColor:'white', color:'black'}}>
                {props.saving ? 'SUBMITTING...' : 'Submit Equipment'}
            </Button>

        
        </Container>
      </Box>    
    </React.Fragment>
    );
}
export default EquipmentCard;