import React, { useEffect, useState} from 'react';
import { useNavigate  } from "react-router-dom";
import Img from 'react-cool-img';
import Sleeve from '../../img/Sleeve.png';
import PromoSleeve from '../../img/PromoSleeve.png';
import { useCards } from '../../providers/cardProvider';
import { Grid, useMediaQuery } from '@mui/material';
import bleed from '../../img/bleed.png';
import Sparkles from "../../components/Sparkles";
import Flippy, { FrontSide, BackSide } from 'react-flippy';

const CardTable = (props) => {
    const matches = useMediaQuery('(max-width:1000px)');
    const [loading, setLoading] = useState(true);
    const [selectedCard, setSelectedCard] = useState({});
    const { character, equipment, locations, virus, ritual, sacrifice, god, galaxy } = useCards();
    const navigate = useNavigate();

    function getCardList(cardType) {
      switch(cardType) {
        case 'character': return character;
        case 'equipment': return equipment;
        case 'locations': return locations;
        case 'virus': return virus;
        case 'ritual': return ritual;
        case 'sacrifice': return sacrifice;
        case 'god': return god;
        case 'galaxy': return galaxy;
        default: return [];
      }
    }

    //get index of card in list us
    const getCardIndex = (id, name, type) => {
        let cardList = []
        cardList = getCardList(type).filter(card=> card.name.toLowerCase().replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === name)
        let index = cardList.findIndex((item) => {
          return item.id === id;
        })
    
        return index;
      }


    const goToCard = (id, name, type) => {
        navigate(`/cards/${type}/${name}-${id}`, { state: { id: name},
      });
      
    }

    const navigateToCard = (card) => {
        goToCard(getCardIndex(card.id, card.name.toLowerCase().replace(/\s/g, '-')
                .replace(/#/g, '')
                .replace(/[.:'"]/g, ''), card.type), card.name.toLowerCase().replace(/\s/g, '-')
                .replace(/#/g, '')
                .replace(/[.:'"]/g, ''), card.type)
    }

    useEffect(() => {
      if(!sessionStorage.getItem(props.cardType)){
        setLoading(true);
        let response = [];
        response = getCardList(props.listType)
        

          sessionStorage.setItem(props.cardType, JSON.stringify(response));
          setLoading(false);
        
          } else {
            setLoading(false);
          }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [])

    const cardImage = (card, sleeveImage) => {
                return (
                <Flippy
                    flipOnClick={true} // default false
                    flipDirection="horizontal" // horizontal or vertical
                    onDoubleClick={() => navigateToCard(card)}
                    >
                <FrontSide style={{ boxShadow: '0 0px 0px 0 rgba(0,0,0,0)'}} >
                <Img placeholder={card.promo ? PromoSleeve : sleeveImage} src={card.cardImage} alt={card.name} style={{width:matches?'120px':'200px', cursor:'pointer',
                    maskImage: `url(${bleed})`,
                    maskSize: matches?'120px auto':'200px auto',
                    maskRepeat: 'no-repeat',
                    maskPosition: 'center',
                    WebkitMaskImage: `url(${bleed})`,
                    WebkitMaskSize:  matches?'120px auto':'200px auto',
                }} 
                >
                </Img>
                </FrontSide>
                <BackSide style={{ boxShadow: '0 0px 0px 0 rgba(0,0,0,0)'}} >
                <Img placeholder={card.promo ? PromoSleeve : sleeveImage} 
                src={card.promo ? PromoSleeve : sleeveImage} alt={card.name} style={{width:matches?'120px':'200px', cursor:'pointer',
                    maskImage: `url(${bleed})`,
                    maskSize: matches?'120px auto':'200px auto',
                    maskRepeat: 'no-repeat',
                    maskPosition: 'center',
                    WebkitMaskImage: `url(${bleed})`,
                    WebkitMaskSize:  matches?'120px auto':'200px auto',
                }}
                >
                </Img>
                </BackSide>
                </Flippy>
                )
    }


  return (<React.Fragment>
{loading && props.cardData.length > 0 ? null :
      <Grid container style={{justifyContent:'center', marginBottom:'16px'}}>
      <Grid container spacing={1} style={{}}>
        {props.cardData.map((card, index) => {
          return (
            <Grid item xs={2} key={index} style={{marginRight:'32px'}}>
              {card.foil || card.cloudyfoil ?
                <Sparkles>
                    {cardImage(card, Sleeve)}
                </Sparkles>
              :
                cardImage(card, Sleeve)
          }
            </Grid>
          )
        }
        )}
        </Grid>
        </Grid>
}
    </React.Fragment>
  );
}
export default CardTable;