import * as React from 'react';
import { firestore, removeFile, uploadFile } from '../../firebase/firebase';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
//import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import CharacterCard from '../components/CharacterCard';
import EquipmentCard from '../components/EquipmentCard';
import SacrificeCard from '../components/SacrifieCard';
import LocationCard from '../components/LocationCard';
import VirusCard from '../components/VirusCard';
import GalaxyCard from '../components/GalaxyCard';
import GodCard from '../components/GodCard';
import RitualCard from '../components/RitualCard';
import { FormControl, InputLabel, MenuItem, Select, Checkbox } from '@mui/material';
import _, { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';

const CreateCard = (props) => {

    const matches = useMediaQuery('(max-width:600px)');
    const [type, setType]   = React.useState( sessionStorage.getItem('creatingType') ? JSON.parse(sessionStorage.getItem('creatingType')) : 'Character');
    const [saving, setSaving] = React.useState(false);
    const [cardData, setCardData] = React.useState({});
    const [saveState, setSaveState] = React.useState({});
    const [sendingDiscord, setSendingDiscord] = React.useState(true);
    const functions = getFunctions();
    const sendDiscord = httpsCallable(functions, 'postCardToDiscord');
    const navigate = useNavigate()
    const [sending, setSending] = React.useState(false);
    const [initialCharacterData] = React.useState(
        {
            name: "",
            Franchise: "",
            cardImage: "",
            description: "",
            ability: "",
            origin: "",
            foil: false,
            hybrid: false,
            cloudyfoil: false,
            freenian: false,
            banned: false,
            bannedReason: '',
            ATK: 0,
            DEF: 0,
            artist: "",
        }
    );
    const [initialGalaxyData] = React.useState(
        {
            name: "",
            Franchise: "",
            materialCount: 2,
            cardImage: "",
            ability: "",
            foil: false,
            banned: false,
            bannedReason: '',
            origin: "",
            ATK: 0,
            DEF: 0,
            artist: "Derek Miller-Young",
        }
    );
    const [initialGodData] = React.useState(
        {
            name: "",
            summonRequirements: "",
            cardImage: "",
            ability: "",
            foil: false,
            banned: false,
            bannedReason: '',
            origin: "",
            ATK: 0,
            DEF: 0,
            artist: "Derek Miller-Young",
        }
    );
    const [initialRitualData] = React.useState(
        {
            name: "",
            summonRequirements: "",
            cardImage: "",
            ability: "",
            foil: true,
            banned: false,
            bannedReason: '',
            origin: "",
            ATK: 0,
            DEF: 0,
            artist: "",
        }
    );
    const [initialLocationData] = React.useState(
        {
            name: "",
            cardImage: "",
            FranchiseBuff: "",
            FranchiseDebuff: "",
            description: "",
            ability: "",
            foil: false,
            banned: false,
            bannedReason: '',
            buff:"",
            debuff:'',
            turns: 0,
            origin: "",
            artist: "",
        }
    );
    const [initialSacrificeData] = React.useState(
        {
            name: "",
            cardImage: "",
            description: "",
            origin: "",
            foil: false,
            banned: false,
            bannedReason: '',
            ATK: 0,
            DEF: 0,
            Level: 2,
            artist: "",
        }
    );
    const [initialEquipmentData] = React.useState(
        {
            name: "",
            eType: "Equip",
            cardImage: "",
            description: "",
            ability: "",
            foil: false,
            banned: false,
            bannedReason: '',
            origin: "",
            artist: "",
            ritualSummon: ""
        }
    );
    //ΘΩΣ
    const [initialVirusData] = React.useState(
        {
            name: " Virus",
            vType: "Φ",
            cardImage: "",
            foil: false,
            banned: false,
            bannedReason: '',
            turns: 4,
            ability: "",
            origin: "",
            artist: "Derek Miller-Young",
        }
    );

    const updateData = React.useCallback((e) => {
        setCardData(e)
    },
        [],
    );


    //useEffect for media query
    React.useEffect(() => {
  
    } , [matches])


    const uploadPhoto = async (photo) => {
        var name = cardData.foil ? cardData.name+'_FOIL' : cardData.name
        name += '_'+cardData.origin+'_'+cardData.artist+'_'+new Date().getTime()
        //eslint-disable-next-line
        name = name.replace(/[\(\)]/g, '')
        var type2 = null
        if (type === 'Character') {
            type2 = 'characters'
        } else if (type === 'Equipment') {
            type2 = 'equipment'
        } else if (type === 'Sacrifice') {
            type2 = 'sacrifice'
        } else if (type === 'Location') {
            type2 = 'location'
        } else if (type === 'Virus') {
            type2 = 'virus'
        } else if (type === 'Galaxy') {
            type2 = 'galaxy'
        } else if (type === 'God') {
            type2 = 'god'
        } else if (type === 'Ritual') {
            type2 = 'ritual'
        }

        let path = 'cards/'+type2+'/' + name + '.png';
        let query = await uploadFile(path, photo);


        return query.url;
    }

    const removePhoto = async (photoURL) => {

        let query = await removeFile(photoURL);

        return query.fileDeleted;
    }




    const saveData = async () => {
        setSaving(true);
        
        //Remove header photo
        if (saveState.cardImage !== cardData.cardImage)
            await removePhoto(saveState.cardImage);

        //Add new header photo
        cardData.cardImage = cardData.cardImage instanceof File
            ? await uploadPhoto(cardData.cardImage)
            : cardData.cardImage

        var type2 = null
        var path = null
        if (type === 'Character') {
            type2 = 'characters'
            path = 'character'
        } else if (type === 'Equipment') {
            type2 = 'equipment'
            path = 'equipment'
        } else if (type === 'Sacrifice') {
            type2 = 'sacrifice'
            path = 'sacrifice'
        }
        else if (type === 'Location') {
            type2 = 'location'
            path = 'location'
        }
        else if (type === 'Virus') {
            type2 = 'virus'
            path = 'virus'
        } else if (type === 'Galaxy') {
            type2 = 'galaxy'
            path = 'galaxy'
        } else if (type === 'God') {
            type2 = 'god'
            path = 'god'
        } else if (type === 'Ritual') {
            type2 = 'ritual'
            path = 'ritual'
        }
        cardData.type = path
        cardData.id = uuidv4();
        if(cardData.origin.length > 0){
            firestore.collection('expansions').orderBy('name','asc').get()
            .then(querySnapshot => {
                let logoResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                }
                })
                cardData.boxImage = logoResponse.filter(logo => logo.name === cardData.origin)[0].boxImage
            })
        }
        
       
        if(cardData.Franchise){
            
            firestore.collection('logos').orderBy('name','asc').get()
            .then(querySnapshot => {
                let logoResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                }
                })
                cardData.logoImage = logoResponse.filter(logo => logo.name === cardData.Franchise)[0].image
            })
        }
        if(cardData.FranchiseBuff){
            firestore.collection('logos').orderBy('name','asc').get()
            .then(querySnapshot => {
                let logoResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                }
                })
                cardData.buffImage = logoResponse.filter(logo => logo.name === cardData.FranchiseBuff)[0].image
            })
        }
        if(cardData.FranchiseDebuff){
            firestore.collection('logos').orderBy('name','asc').get()
            .then(querySnapshot => {
                let logoResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                }
                })
                cardData.debuffImage = logoResponse.filter(logo => logo.name === cardData.FranchiseDebuff)[0].image
            })
        }
        
        firestore.collection('cards').doc(type2).get().then(doc => {
            if (doc.exists) {
                var data = doc.data().cards;
             
                var newData = _.concat(data, cardData);
          
                firestore.collection('cards').doc(type2).update({cards: newData})
                //update the session storage
                if(type === 'Character'){
                    sessionStorage.setItem('characters', JSON.stringify(newData));
                } else if (type === 'Equipment') {
                    sessionStorage.setItem('equipments', JSON.stringify(newData));
                } else if (type === 'Sacrifice') {
                    sessionStorage.setItem('sacrifices', JSON.stringify(newData));
                } else if (type === 'Location') {
                    sessionStorage.setItem('locations', JSON.stringify(newData));
                } else if (type === 'Virus') {
                    sessionStorage.setItem('viruses', JSON.stringify(newData));
                } else if (type === 'Galaxy') {
                    sessionStorage.setItem('galaxies', JSON.stringify(newData));
                } else if (type === 'God') {
                    sessionStorage.setItem('gods', JSON.stringify(newData));
                } else if (type === 'Ritual') {
                    sessionStorage.setItem('rituals', JSON.stringify(newData));
                }

            }
        })
        firestore.collection('logos').orderBy('name','asc').get()
        .then(querySnapshot => {
            let logoResponse = querySnapshot.docs.map((doc) => {
            return {
                ...doc.data(),
            }
            })
            sendingDiscord && postDiscord(logoResponse.filter(logo => logo.name === cardData.Franchise)[0]);
        })
        

        //let docId = cardData.id
        setSaveState(_.cloneDeep(cardData));
        
        //timeout to allow the database to update
        setTimeout(() => {
            setSaving(false)
            navigate('/cards');
        }, 3000)



        
      
    };

    const cancelSave = () => {
        setCardData(_.cloneDeep(saveState));
    };

    React.useEffect(() => {


        

        setCardData(cloneDeep(initialCharacterData));
        setSaveState(cloneDeep(initialCharacterData));
   
        
       

    }, [initialCharacterData])

    const postDiscord = async (logoData) => {
        if(!sending) {
            setSending(true)
            await sendDiscord({
                cardData: cardData,
                franchiseData: logoData
            }).then((res) => {
                console.log(res)
                setSending(false)
            }).catch((err) => {
                console.warn(err)
                setSending(false)
            })
        }
    }
    

    return (
    <React.Fragment>
        
        <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            '#075d8e',
          margin: "10%",
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="xl" sx={{ p: 4 }}>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Card Type</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Card Type"
                variant='outlined'
                onChange={(e) => {setType(e.target.value); sessionStorage.setItem('creatingType', JSON.stringify(e.target.value));}}
            >
                <MenuItem value={'Character'}>Character</MenuItem>
                <MenuItem value={'Equipment'}>Equipment</MenuItem>
                <MenuItem value={'Sacrifice'}>Sacrifice</MenuItem>
                <MenuItem value={'Location'}>Location</MenuItem>
                <MenuItem value={'Virus'}>Virus</MenuItem>
                <MenuItem value={'Galaxy'}>Galaxy</MenuItem>
                <MenuItem value={'God'}>God</MenuItem>
                <MenuItem value={'Ritual'}>Ritual</MenuItem>
            </Select>
            </FormControl>
            {//checkbox to send to discord
            }
            <FormControl fullWidth style={{backgroundColor:'white'}}>
            <Checkbox
                checked={sendingDiscord}
                onChange={(e) => {setSendingDiscord(e.target.checked)}}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            <InputLabel id="demo-simple-select-label">Send to Discord</InputLabel>
            </FormControl>
        <br/>
        {type === "Character" ? <CharacterCard characterData={initialCharacterData} updateData={updateData} cancelSave={cancelSave} saveState={saveState} saveData={saveData}
        saving={saving}/> : null}
        {type === "Equipment" ? <EquipmentCard equipmentData={initialEquipmentData} updateData={updateData} cancelSave={cancelSave} saveState={saveState} saveData={saveData}
        saving={saving}/> : null}
        {type === "Sacrifice" ? <SacrificeCard sacrificeData={initialSacrificeData} updateData={updateData} cancelSave={cancelSave} saveState={saveState} saveData={saveData}
        saving={saving}/> : null}
        {type === "Location" ? <LocationCard locationData={initialLocationData} updateData={updateData} cancelSave={cancelSave} saveState={saveState} saveData={saveData}
        saving={saving}/> : null}
        {type === "Virus" ? <VirusCard virusData={initialVirusData} updateData={updateData} cancelSave={cancelSave} saveState={saveState} saveData={saveData}
        saving={saving}/> : null}
        {type === "Galaxy" ? <GalaxyCard galaxyData={initialGalaxyData} updateData={updateData} cancelSave={cancelSave} saveState={saveState} saveData={saveData}
        saving={saving}/> : null}
        {type === "God" ? <GodCard godData={initialGodData} updateData={updateData} cancelSave={cancelSave} saveState={saveState} saveData={saveData}
        saving={saving}/> : null}
        {type === "Ritual" ? <RitualCard ritualData={initialRitualData} updateData={updateData} cancelSave={cancelSave} saveState={saveState} saveData={saveData}
        saving={saving}/> : null}
        </Container>
        </Box>
        
        </React.Fragment>
    );
}
export default CreateCard;